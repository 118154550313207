import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Empty from 'src/components/Empty';
import Svg404 from 'src/assets/icons/404.svg';
import { Button } from 'antd';

export default function Error404() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Empty
      icon={Svg404}
      title="404"
      description={t('error.404')}
      extraNode={<Button onClick={() => navigate('/')}>{t('error.go_home')}</Button>}
    />
  );
}
